export const REPORTS_MOCK = [
  {
    id: 1,
    type: 'country',
    entity: 'Brazil',
    status: 'published',
    createdAt: new Date().toISOString(),
    createdBy: 'John Doe',
    versions: [
      {
        version: 6,
        status: 'draft',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        version: 5,
        status: 'published',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        version: 4,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        version: 3,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        version: 2,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        version: 1,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
    ],
  },
  {
    id: 2,
    type: 'country',
    entity: 'USA',
    status: 'published',
    createdAt: new Date().toISOString(),
    createdBy: 'John Doe',
    versions: [
      {
        version: 5,
        status: 'published',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        version: 4,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        version: 3,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        version: 2,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
      {
        version: 1,
        status: 'archived',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
    ],
  },
  {
    id: 3,
    type: 'country',
    entity: 'GoM',
    status: 'draft',
    createdAt: new Date().toISOString(),
    createdBy: 'John Doe',
    versions: [
      {
        version: 1,
        status: 'draft',
        createdAt: new Date().toISOString(),
        createdBy: 'John Doe',
      },
    ],
  },
  {
    id: 4,
    type: 'corporate',
    entity: 'Shell',
    status: 'draft',
    createdAt: new Date().toISOString(),
    createdBy: 'Jane Doe',
    versions: [
      {
        version: 1,
        status: 'draft',
        createdAt: new Date().toISOString(),
        createdBy: 'Jane Doe',
      },
    ],
  },
  {
    id: 5,
    type: 'asset',
    entity: 'P-51',
    status: 'draft',
    createdAt: new Date().toISOString(),
    createdBy: 'Jane Doe',
    versions: [
      {
        version: 1,
        status: 'draft',
        createdAt: new Date().toISOString(),
        createdBy: 'Jane Doe',
      },
    ],
  },
];
