import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createMap, rulerEvent } from './Utils/utils';
import { geojson, linestring } from './map.constants';
import { getOtherDistanceUnits, useDistanceUnits } from '../../utils/distanceUnits';

import { CLICKABLE_COUNTRY_QUERY } from './operations';
import { ChangeUnitsButton } from './ChangeUnitsButton';
import Legend from '../Legend';
import { MAP_TILESET_TYPES } from './Utils/constants';
import { RulerButton } from './RulerButton';
import Settings from './Settings';
import clsx from 'clsx';
import { getUserPreferenceMapStyle } from './Utils/mapStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { useFeatureFlags } from '../../contexts/FeatureFlagsContext';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  hiddenContainer: { ...theme.palette.hiddenContainer },
  mapContainer: {
    ...theme.sizes.fullPage,
    position: 'relative',
    '& .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl': {
      marginBottom: 0,
      '&:nth-last-child(2), &:last-child': {
        marginBottom: '10px',
      },
    },
    '& .mapboxgl-ctrl-group button': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  rulerAction: {
    color: theme.palette.baseColors.deepSeaNavy.c100,
    borderRadius: '0',
    marginLeft: '0',
    '&:hover': {
      filter: 'brightness(95%)',
    },
  },
  rulerActionSelected: {
    backgroundColor: `${theme.palette.baseColors.deepSeaNavy.c100} !important`,
    color: `${theme.palette.baseColors.white.c100} !important`,
    borderRadius: '0',
    marginLeft: '0',
  },
  secondLegendContainer: {
    bottom: '83px',
    maxWidth: 'calc(100% - 160px)',
  },
}));

/**
 * Base map component for rendering a map including the legend and settings panel
 * @param {object} props
 * @param {string} props.mapContainerId - the id of the map container
 * @param {object} props.map - the part of state set for the map
 * @param {function} props.setMap - function to set the map in state
 * @param {string} props.style (optional) - the style for map (default satellite if no user option)
 * @param {function} props.setStyle (optional) - function to set the style in state
 * @param {array} props.layers (optional) - array of layers to render (default none)
 * @param {array} props.layerFilters (optional) - array of filters to apply to the map layers (default none)
 *  ex: [
 *    {
 *      entityTypes: ['assets', 'blocks'],
 *      filter: ['match', ['get', 'countryIsoCode'], ['USA'], true, false]
 *    }
 *  ]
 * @param {object} props.layerStyle (optional) - object to style a layer based on metric and sizeType (default none)
 *  ex: {
 *    layer: 'co2 emitters',
 *    metric: 'Total CO2 Emissions',
 *    sizeType: 'Total Abatement Cost',
 *  }
 * @param {array} props.hideLayers (optional) - array of layers to hide at time of map creation (default none)
 * @param {boolean} props.enableEntityHighlight (optional) - flag for including entity type highlight layers (default off)
 * @param {boolean} props.enableInfoPopup (optional) - flag for including the info popup (default off)
 * @param {function} props.layerClickCallback (optional) - function for when a user clicks an item on the map
 *  (default nothing happens)
 * @param {object} props.legendProps (optional) - props for legend component
 *  ex (non-tabbed): {
      enabled: true, // flag for including the legend (default off)
      items: [ // array of items to display for the map legend (default none)
        { color: WELL_COLORS.exploration_and_appraisal, title: 'Exploration' },
        { color: WELL_COLORS.production, title: 'Production' },
        { color: WELL_COLORS.injection, title: 'Injection' },
        { color: WELL_COLORS.wells_to_watch, title: 'E&A Wells to Watch' },
        { color: WELL_COLORS.default, title: 'Other' },
      ],
      title: 'Legend: Wells', // title for the legend (default none)
    }
    ex (tabbed): {
      enabled: open && showLegend, // flag for including the legend (default off)
      keepMounted: true, // flag for keeping the legend mounted when closed - this enables transition effect (default off)
      tabbed: true, // flag for using tabbed legend (default off)
      tabbedItems: { // array of tabbed items to display for the map legend (default none)
        label: 'CCUS Project Industry Types', // tab label
        multiline: true, // flag for allowing the legend to take up multiple lines (default false)
        items: INDUSTRY_LEGEND_ITEMS, // array of items to display for the map legend - follows same format as non-tabbed
          items above
        itemBorder: 'solid thin white', // border color for the legend items (default black)
      },
    }
 * @param {boolean} props.control (optional) - flag for including the map controls (default off)
 * @param {boolean} props.ruler (optional) - flag for including ruler feature (default off)
 * @param {boolean} props.rulerActive (optional) - boolean if the ruler is activated (default false)
 * @param {function} props.setRulerActive (optional) - function to toggle the ruler feature
 * @param {object} props.styles (optional) - styles to override the default classes
 * @param {object} props.settingsProps (optional) - props for settings component
 *  ex: {
 *    enabled: true,
 *    includeMapStyle: true,
 *    includeLayers: true,
 *    layerProps: {
 *      defaultLayer: 'productionHeatMap', // the default layer to render (optional, default none)
 *      items: [], // items can be found in ./settings/constants.js (optional, default ALL)
 *      filters: {}, // filters to pass to layer query (optional, default none)
 *    },
 *  }
 * @param {object} props.syncMap (optional) - reference to another part of state set for a map to sync actions with
 *  (default none)
 * @param {object} props.callbacks (optional) - callbacks to attach to mapbox events
 *  ex: {
 *    zoomend: () => {},
 *  }
 * @param {object} props.countryClickProps (optional) - params for interactive countries (optional, default none)
 *  ex: {
 *    enabled: true,
 *    filters: {}, // filters to pass to query for available user countries
 *    selectedCountry: {} // country object that is the user selected country
 *  }
 * @param {function} props.removeLayerCallback (optional) - callback for when a layer is disabled
 * @param {object} props.defaultMapPosition (optional) - the position of the map (default from url if exists)
 *  ex: {
 *    latLong: `[${map.getCenter().lng}, ${map.getCenter().lat}]`, -> "[0.00000, 0.00000]"
 *    zoom: map.getZoom(), -> 0.00
 *  }
 * @param {string} props.tilesetType (optional) - the type of tilesets to use (default MAP_TILESET_TYPES.webPlatform)
 */
export default ({
  mapContainerId = 'mapContainer',
  map,
  setMap,
  style = 'monochrome',
  setStyle = () => {},
  layers = [],
  layerFilters = [],
  layerStyle,
  hideLayers = [],
  enableEntityHighlight,
  enableInfoPopup,
  layerClickCallback,
  legendProps = {},
  control,
  ruler,
  styles = {},
  settingsProps = {},
  syncMap,
  callbacks = {},
  countryClickProps = {},
  removeLayerCallback = () => {},
  defaultMapPosition,
  tilesetType = MAP_TILESET_TYPES.webPlatform,
}) => {
  const { flagsLoaded, inDevelopment, mapboxPoc } = useFeatureFlags();
  const classes = useStyles();
  const mapPosition = useParams();
  const { mapCcusTilesetSources, mapTilesetSources } = useCurrentUser();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [rulerActive, setRulerActive] = useState(false);
  const { distanceUnits, setDistanceUnits } = useDistanceUnits();

  const onDistanceUnitsChange = useCallback(() => {
    const newMetric = getOtherDistanceUnits(distanceUnits);
    setDistanceUnits(newMetric);
    map._scaleControl?.setUnit(newMetric);
    map.onClickRulerCallback = rulerEvent(map, geojson, linestring, newMetric);
  }, [map, distanceUnits, setDistanceUnits]);

  // prepend legend to mapbox controls container if legend is enabled or we want to keep the legend mounted
  const setLegend = useCallback(
    (node) => {
      if (mapLoaded && node) {
        // mapbox root controls container
        const controlContainer = document.getElementsByClassName('mapboxgl-control-container')[0];
        // insert the legend just before the bottom-right controls parent container (nav controls and map scale)
        const bottomRightControlContainer = document.getElementsByClassName(
          'mapboxgl-ctrl-bottom-right',
        )[0];
        controlContainer.insertBefore(node, bottomRightControlContainer);
      }
    },
    [mapLoaded],
  );

  const { data } = useQuery(CLICKABLE_COUNTRY_QUERY, {
    variables: {
      filters: countryClickProps.filters,
      selectedCountry: countryClickProps.selectedCountry,
    },
    skip: !countryClickProps.enabled,
  });

  // use either user preference map style or the default for the component
  const mapStyle = useMemo(() => getUserPreferenceMapStyle(style), [style]);

  // select which tilesets to load from the current user object
  const tilesetSources = useMemo(() => {
    switch (tilesetType) {
      case MAP_TILESET_TYPES.webPlatform:
        return mapTilesetSources;
      case MAP_TILESET_TYPES.ccus:
        return mapCcusTilesetSources;
      default:
        return mapTilesetSources;
    }
  }, [tilesetType, mapTilesetSources, mapCcusTilesetSources]);

  useEffect(() => {
    // create map when user loads - need user permissions
    if (tilesetSources && flagsLoaded) {
      const enableCountryClick = !!countryClickProps.enabled;
      const newMap = createMap({
        callbacks,
        control,
        enableCountryClick,
        enableEntityHighlight,
        enableInfoPopup,
        geojson,
        hideLayers,
        initialMetric: layerStyle?.metric,
        initialSizeType: layerStyle?.sizeType,
        latLong: defaultMapPosition?.latLong || mapPosition.latLong,
        layerClickCallback,
        layerFilters,
        layers,
        linestring,
        mapContainerId,
        mapTilesetSources: tilesetSources,
        ruler,
        setMapLoaded,
        setRulerActive,
        setStyle,
        style: mapStyle,
        zoom: defaultMapPosition?.zoom || mapPosition.zoom,
        inDevelopment,
        mapboxPoc,
        defaultDistanceMetric: distanceUnits,
      });
      setMap(newMap);
    }
  }, [tilesetSources, flagsLoaded]);

  useEffect(() => {
    if (inDevelopment && mapLoaded) {
      console.log('mapLoaded', map);
      window.map = map;
    }
  }, [inDevelopment, mapLoaded]);

  useEffect(() => {
    // set the country boundary style when the country data query returns
    if (mapLoaded && data && data.mapInteractiveCountries?.feature) {
      map.layerCallbacks['setCountryBoundaryStyle'](data.mapInteractiveCountries.feature);
    }
  }, [data, mapLoaded]);

  useEffect(() => {
    // apply filters on the map layers
    if (mapLoaded && layerFilters.length > 0) {
      map.layerCallbacks.filterLayers(layerFilters);
    }
  }, [layerFilters, mapLoaded]);

  useEffect(() => {
    // change the layer style based on the metric and sizeType
    if (mapLoaded && layerStyle) {
      const { layer, metric, sizeType } = layerStyle;
      map.layerCallbacks.changeLayerStyle(layer, metric, sizeType);
    }
  }, [layerStyle, mapLoaded]);

  const toggleMapStyle = (newStyle) => {
    setMapLoaded(false);
    map.toggleMapStyle(newStyle);
    if (syncMap) {
      syncMap.toggleMapStyle(newStyle);
    }
  };

  // add a layer to the map from the settings panel
  const addLayer = (layer, style) => {
    map.layerCallbacks[layer.call](style);
    if (syncMap) {
      syncMap.layerCallbacks[layer.call](style);
    }
  };

  // remove a layer from the map from the settings panel
  const removeLayer = (layer) => {
    map.layerCallbacks[layer.call]();
    removeLayerCallback();
    if (syncMap) {
      syncMap.layerCallbacks[layer.call]();
    }
  };

  // toggle a layer's visibility from the settings panel
  const toggleLayer = (layer, visibility) => {
    map.layerCallbacks[layer.call](layer.entityTypes, visibility);
    if (syncMap) {
      syncMap.layerCallbacks[layer.call](layer.entityTypes, visibility);
    }
  };

  return (
    <>
      <div
        id={mapContainerId}
        className={clsx({
          [classes.mapContainer]: true,
          [styles.mapContainer]: !!styles.mapContainer,
        })}
      />
      <div id="distanceContainer" />
      {/* we keep the legend and ruler in a hidden container with no visibility and position absolute
    this ensures that the legend and ruler do not affect the map layout
    before they can be appended to the mapbox controls */}
      <div className={classes.hiddenContainer}>
        <div ref={legendProps.enabled || legendProps.keepMounted ? setLegend : () => {}}>
          {(legendProps.enabled || legendProps.keepMounted) && (
            <Legend styles={styles} multiline {...legendProps} />
          )}
        </div>
        {ruler && (
          <RulerButton
            rulerActive={rulerActive}
            setRulerActive={setRulerActive}
            map={map}
            mapLoaded={mapLoaded}
            distanceUnits={distanceUnits}
          />
        )}
        {ruler && (
          <ChangeUnitsButton
            rulerActive={rulerActive}
            mapLoaded={mapLoaded}
            distanceUnits={distanceUnits}
            onDistanceUnitsChange={onDistanceUnitsChange}
          />
        )}
      </div>
      {settingsProps.enabled && (
        <Settings
          {...settingsProps}
          layerProps={{ ...settingsProps.layerProps, mapLoaded }}
          legendContainerRef={
            // don't show the settings legend if the map hasn't loaded or the map legend is enabled
            !(legendProps.enabled || legendProps.keepMounted) ? setLegend : null
          }
          addLayer={addLayer}
          mapStyle={mapStyle}
          removeLayer={removeLayer}
          toggleLayer={toggleLayer}
          toggleMapStyle={toggleMapStyle}
        />
      )}
    </>
  );
};
