import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useEffect, useMemo, useState } from 'react';

import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { CustomOptions } from './CustomOptions';
import { FilterLabels } from '../Filters/filterTypes';
import { SearchLabels } from '../../pages/OilAndGasMap/Filters/searchTypes';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { getFulltextResultParameters } from '../../utils/globalSearch';
import { getMatchedValueFromAutocomplete } from '../GlobalSearch/helper';
import makeStyles from '@mui/styles/makeStyles';
import { useDebounce } from '../../hooks/useDebounce';
import { useQuery } from '@apollo/client';
import Popper from '@mui/material/Popper';

// This matches the special characters in the BaseQuery search functionality found in
// app/queries/base_query.rb
const removeSpecialCharacters = (str) => str.replace(/[-_/\\()[\],"'.\s]/g, '');

// Custom search that ignores special characters in the options labels
const getFilterOptions = (key) =>
  createFilterOptions({
    stringify: (option) => removeSpecialCharacters(`${option[key]}`),
  });

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    margin: theme.spacing(1),

    '& .MuiInputBase-root': {
      paddingTop: '0px',
    },
  },
  chips: {
    height: '18px',
    minWidth: '1px',
  },
  chipsNoLimit: {
    width: 'auto',
  },
  chipsDelete: {
    height: '15px',
    margin: '0 0 0 -1px',
  },
  chipsLabel: {
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  text: {
    fontSize: '14px',
    boxSizing: 'border-box',
    borderRadius: '5px',
  },
  inputBase: {
    marginLeft: '11px',
  },
  input: {
    fontFamily: theme.fonts.base,
    boxSizing: 'border-box',
    borderRadius: '5px',
  },
  inputNoLimit: {
    width: 'auto',
  },
  icon: {
    color: '#2a2e49',
  },
  listbox: {
    ...theme.palette.scrollbar,
    fontFamily: theme.fonts.base,
    fontSize: theme.fontSizes.base,
    maxHeight: '300px',
    overflowY: 'auto',
    margin: 0,
    padding: 0,
    whiteSpace: 'unset',
  },
  startAdornment: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '50%',
    position: 'relative',
    fontSize: '13px',
    marginTop: '10px',
  },
  startAdornmentNoLimit: {
    maxWidth: 'none',
    minWidth: '100px',
  },
  endAdornment: {
    display: 'flex',
    position: 'relative',
    top: 'auto',
  },
  fullHeight: {
    height: '100%',
  },
  asterisk: {
    color: '#e43246',
  },
  control: {
    padding: '5px 16px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  },
  loaderWrapper: {
    paddingRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const useDynamicStyles = makeStyles(() => ({
  customOption: {
    marginBottom: (props) => (props.bottomOffset ? `${props.bottomOffset}px` : 0),
  },
}));

const CUSTOM_OPTION_TYPE = 'customOption';

const CustomPopper = (props) => (
  <Popper {...props} style={{ minWidth: 290, maxWidth: 350 }} placement="bottom-start" />
);

export default ({
  getData,
  onDataLoaded,
  menuItems,
  label,
  onChange,
  value,
  optionKey,
  groupOptionKey,
  propVariables = {},
  runQueryAfterOnChange,
  queryVariable,
  multiple,
  styles = {},
  menuItemsCallback,
  disabled,
  note,
  disableLimitTags,
  responseCallback,
  hideItem,
  control = {},
  context = '',
  required,
  globalSearchType,
  id,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [groupedOptions, setGroupedOptions] = useState({});
  const [getValueFromSearchParams, setGetValueFromSearchParams] = useState(false);

  const debouncedSearchValue = useDebounce(searchValue, 300);

  /* QUERY */
  // Append query variable to variables object for passing to query
  const variables = useMemo(() => {
    const variables = propVariables;
    if (queryVariable) variables[queryVariable] = '';
    return variables;
  }, [propVariables, queryVariable]);

  const searchParams = getFulltextResultParameters();

  // Parse query response
  function parseQueryResponse(res) {
    // res structure is { <queryName>: <data> }, so we need to get the data
    // from the first key in the object
    // e.g. { "basinsWithFilter": [...] }
    // e.g. { "assetsByNameWithFilter": { assets: [], totalCount: 0 } }
    const value = Object.values(res)[0];
    if (!value) return [];
    let response = responseCallback ? responseCallback(value) : value;
    return response.filter((item) => !hideItem || !hideItem(item));
  }

  const menuNameFilters = [
    FilterLabels.assets,
    SearchLabels.asset,
    SearchLabels.block,
    SearchLabels.facility,
    SearchLabels.field,
    SearchLabels.lngFacility,
    SearchLabels.well,
  ];

  const addMenuNameToOptions = (options, property) => {
    const properties = options.map((option) => option[property]);
    const duplicates = properties.filter(
      (property, index) => index !== properties.lastIndexOf(property),
    );
    return options.map((option) => {
      let menuName = option[property];
      if (duplicates.includes(option[property]) && option?.country?.displayName) {
        menuName += ` (${option.country.displayName})`;
      }
      return {
        ...option,
        menuName,
      };
    });
  };

  const postProcessOptions = (options) => {
    if (menuNameFilters.includes(label)) {
      if (options.every((option) => option.displayName)) {
        options = addMenuNameToOptions(options, 'displayName');
      } else {
        options = addMenuNameToOptions(options, 'name');
      }
    }
    return options;
  };

  // Query to populate the options
  const query = () =>
    useQuery(getData, {
      variables,
      notifyOnNetworkStatusChange: true,
      onCompleted: (res) => {
        onDataLoaded && onDataLoaded(res);

        if (!res) return;
        const response = parseQueryResponse(res);
        const { items, groupedOptions } = menuItemsCallback ? menuItemsCallback(response) : {};
        const options = items || response;

        if (groupedOptions) {
          setGroupedOptions(groupedOptions);
        }
        setOptions(postProcessOptions(options));

        if (globalSearchType && getValueFromSearchParams) {
          const searchedValue = options.find((option) =>
            getMatchedValueFromAutocomplete(option, searchParams),
          );
          if (searchedValue) {
            onChange(searchedValue);
          }
          setGetValueFromSearchParams(false);
        }
      },
      skip: disabled,
    });

  // Set variables from query or menuItems if static dataset
  const { loading, data, refetch } = getData
    ? query()
    : { loading: false, data: menuItems, refetch: null };

  useEffect(() => {
    if (globalSearchType && searchParams && globalSearchType === searchParams.entity) {
      setGetValueFromSearchParams(true);
      // Leases use the block name in the dropdowns
      let name;
      if (globalSearchType === 'BLOCK' && searchParams.isoCode === 'USA') {
        name = searchParams.name.split(' - ')[0];
      } else {
        ({ name } = searchParams);
      }
      setSearchValue(name);
    }
  }, []);

  // Set options from menuItems if getData query doesn't exist
  useEffect(() => {
    if (!getData) setOptions(postProcessOptions(data));
  }, [menuItems]);

  // Refetch data on text change if runQueryAfterOnChange is true
  const textOnChange = (event) => setSearchValue(event.target.value);
  // Debounce the text change
  useEffect(() => {
    if (runQueryAfterOnChange) {
      refetch({ ...variables, [queryVariable]: debouncedSearchValue });
    }
  }, [debouncedSearchValue]);

  const renderCustomOptions = useMemo(() => !!note || !!control.controlItems, [note, control]);

  const [bottomOffset, setBottomOffset] = useState(0);
  const classesDynamic = useDynamicStyles({ bottomOffset });

  // Set options from filtering and make sure to append note and control if needed
  const filterOptions = (options, state) => {
    if (options?.length === 0) return [];
    // Ignore special characters in the search
    state.inputValue = removeSpecialCharacters(state.inputValue);
    const results = getFilterOptions(optionKey)(options, state);
    // When items are grouped, we want to still be able to search for those items by name
    // Ex. CCUS Project is grouped by hub name
    // Hub name = Acorn CCS
    // Project name = Project Cavendish
    // If we search for Project Cavendish we want Acorn CCS to appear in the results
    if (results && groupOptionKey) {
      // Filter the results to get UNIQUE group keys
      const groupedResults = results
        ?.map((r) => r[groupOptionKey])
        .filter((key, idx, self) => key && self.indexOf(key) === idx)
        .map((key) => groupedOptions[key].parent);
      // Find which group keys are not already in the results
      const difference = groupedResults?.filter(
        (gr) =>
          !results?.find(
            (r) => gr[groupOptionKey] === r[groupOptionKey] && gr[optionKey] === r[optionKey],
          ),
      );

      // Add grouped items to the result
      results.push(...difference);
    }

    return results;
  };

  return (
    <div id={id} style={{ display: 'block', height: disableLimitTags ? '100%' : 'auto' }}>
      <Autocomplete
        className={clsx({
          [classes.autoComplete]: true,
          [styles.root]: !!styles.root,
          [classes.fullHeight]: disableLimitTags,
        })}
        disableCloseOnSelect={true}
        disabled={disabled}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(e, val, reason, details = {}) => {
          e.preventDefault();
          e.stopPropagation();
          if (val?.type === CUSTOM_OPTION_TYPE) {
            return;
          }
          let returnValue = val;
          // check if option selected is part of a grouping
          const { option } = details;
          if (groupOptionKey && option) {
            const groupedOption = groupedOptions[option[groupOptionKey]];

            // If the option selected is the parent of a group, we need to add or remove all the child options
            if (groupedOption?.parent[optionKey] === option[optionKey]) {
              switch (reason) {
                // case in which option is selected, add it's grouped options
                case 'select-option':
                case 'selectOption':
                  returnValue = [...val, ...groupedOption.group];
                  break;
                // case in which option is removed, remove it's grouped options
                case 'remove-option':
                case 'removeOption':
                  returnValue = val.filter((v) => !groupedOption.group.find((g) => g.id === v.id));
                  break;
              }
            }
          }

          setOpen(false);
          onChange(returnValue, data);

          if (runQueryAfterOnChange && (returnValue === null || returnValue.length === 0)) {
            textOnChange({ target: { value: '' } });
          }
        }}
        value={value}
        multiple={multiple}
        limitTags={1}
        getOptionDisabled={(option) => option.isDisabled}
        isOptionEqualToValue={(option, value) => option[optionKey] === value[optionKey]}
        getOptionLabel={(option) => (option[optionKey] ? option[optionKey] : '')}
        options={[...options, ...(renderCustomOptions ? [{ type: CUSTOM_OPTION_TYPE }] : [])]}
        filterOptions={filterOptions}
        loading={loading}
        classes={{
          clearIndicatorDirty: classes.icon,
          popupIndicator: classes.icon,
          popperDisablePortal: classes.control,
          endAdornment: clsx({ [classes.endAdornment]: disableLimitTags }),
        }}
        slotProps={{
          chip: {
            classes: {
              root: clsx({ [classes.chips]: true, [classes.chipsNoLimit]: disableLimitTags }),
              deleteIcon: classes.chipsDelete,
              label: classes.chipsLabel,
              ...styles.chips,
            },
          },
          listbox: {
            className: clsx({
              [classes.listbox]: true,
              [classesDynamic.customOption]: renderCustomOptions,
              [styles.listbox]: !!styles.listbox,
            }),
          },
        }}
        renderOption={(props, option) => {
          return option.type === CUSTOM_OPTION_TYPE ? (
            <CustomOptions
              setBottomOffset={setBottomOffset}
              controlClass={classes.control}
              control={control}
              note={note}
              dataFromQuery={data}
              context={context}
            />
          ) : (
            <li {...props}>{option[optionKey]}</li>
          );
        }}
        renderTags={(value, getTagProps) => {
          const visibleValues = hideItem ? value.filter((v) => !hideItem(v)) : value;
          const numTags = visibleValues.length;
          const limitTags = disableLimitTags ? undefined : 1;

          return (
            <>
              {visibleValues.slice(0, limitTags).map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={index}
                  label={option[optionKey]}
                  classes={{
                    root: clsx({ [classes.chips]: true, [classes.chipsNoLimit]: disableLimitTags }),
                    label: classes.chipsLabel,
                    deleteIcon: classes.chipsDelete,
                    ...styles.chips,
                  }}
                />
              ))}
              {numTags > limitTags && ` +${numTags - limitTags}`}
            </>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            className={clsx({
              [classes.text]: true,
              [classes.fullHeight]: disableLimitTags,
              [styles.text]: styles.text,
            })}
            autoComplete="off"
            label={label.toUpperCase()}
            variant="filled"
            onChange={runQueryAfterOnChange ? textOnChange : () => {}}
            required={required}
            slotProps={{
              input: {
                ...params.InputProps,
                className: clsx({
                  [classes.input]: true,
                  [styles.input]: !!styles.input,
                  [classes.fullHeight]: disableLimitTags,
                }),
                classes: {
                  input: clsx(classes.inputBase, {
                    [classes.inputNoLimit]: disableLimitTags,
                  }),
                },
                disableUnderline: true,
                startAdornment: params.InputProps.startAdornment ? (
                  <div
                    className={clsx({
                      [classes.startAdornment]: true,
                      [classes.startAdornmentNoLimit]: disableLimitTags,
                    })}
                  >
                    {params.InputProps.startAdornment}
                  </div>
                ) : undefined,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <div className={classes.loaderWrapper}>
                        <CircularProgress color="inherit" size={20} />
                      </div>
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              },

              htmlInput: {
                ...params.inputProps,
                autoComplete: 'new-password',
              },

              inputLabel: {
                ...params.InputLabelProps,
                classes: {
                  ...params.InputLabelProps?.classes,
                  asterisk: classes.asterisk,
                },
                className: styles.label || null,
              },
            }}
          />
        )}
        slots={{
          popper: CustomPopper,
        }}
      />
    </div>
  );
};
