import { PRICE_PER_BARREL_MIN_MAX, ACQUISITION_COST_MIN_MAX } from '../types';
import { useGetMaFiltersSummary } from './useGetMaFiltersSummary';
import { useMemo } from 'react';
import { roundDownToNearestThousand, roundUpToNearestThousand, roundToNearest } from '../utils';

export const useGetMinMaxFilters = () => {
  const { data, loading } = useGetMaFiltersSummary();

  const { priceFlowingBarrel, acquisitionCost } = useMemo(() => {
    if (!data || loading) {
      return {
        priceFlowingBarrel: PRICE_PER_BARREL_MIN_MAX,
        acquisitionCost: ACQUISITION_COST_MIN_MAX,
      };
    }

    return {
      priceFlowingBarrel: {
        ...PRICE_PER_BARREL_MIN_MAX,
        ...{
          min: {
            ...PRICE_PER_BARREL_MIN_MAX.min,
            default: roundDownToNearestThousand(data.minPriceFlowingBarrelOutliers),
          },
          max: {
            ...PRICE_PER_BARREL_MIN_MAX.max,
            default: roundUpToNearestThousand(data.maxPriceFlowingBarrelOutliers),
          },
        },
      },
      acquisitionCost: {
        ...ACQUISITION_COST_MIN_MAX,
        ...{
          min: {
            ...ACQUISITION_COST_MIN_MAX.min,
            default: roundToNearest(data.minAcquisitionCostOutliers, 10, false),
          },
          max: {
            ...ACQUISITION_COST_MIN_MAX.max,
            default: roundToNearest(data.maxAcquisitionCostOutliers, 10, true),
          },
        },
      },
    };
  }, [data, loading]);

  return { priceFlowingBarrel: priceFlowingBarrel, acquisitionCostMinMax: acquisitionCost };
};
