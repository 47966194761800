import { AddCircleOutline as AddCircleOutlineIcon, Edit as EditIcon } from '@mui/icons-material';
import { Avatar, Box, Button, Chip, Tooltip, Typography } from '@mui/material';
import { REPORT_STATUS_COLORS, REPORT_TYPE_ICONS } from './constants';

import { CollapseStack } from '../CollapseStack';
import { PageShell } from '../PageShell/PageShell';
import React from 'react';
import { styles } from './styles';
import { titleize } from '../../../../utils/helpers';
import { useGetReports } from './hooks/useGetReports';

const AvatarIcon = ({ data, size = 'medium' }) => {
  const getUserInitials = (name) => {
    const [firstName, lastName] = name.split(' ');
    return `${firstName[0]}${lastName[0]}`;
  };

  return (
    <Box display="flex" justifyContent="space-around" alignItems="center">
      <Typography variant="subtitle1">Created by</Typography>
      <Tooltip title={data.createdBy}>
        <Avatar sx={styles.avatar(size)}>{getUserInitials(data.createdBy)}</Avatar>
      </Tooltip>
    </Box>
  );
};

const DataChip = ({ data }) => (
  <Chip sx={styles.chip} label={titleize(data.status)} color={REPORT_STATUS_COLORS[data.status]} />
);

export const SmartReports = () => {
  const { reports } = useGetReports();

  return (
    <PageShell
      title="Smart Reports"
      actions={
        <Button
          sx={styles.button}
          variant="contained"
          color="secondary"
          startIcon={<AddCircleOutlineIcon />}
        >
          Add New Report
        </Button>
      }
    >
      <Box>
        <CollapseStack
          data={reports}
          getDataArray={(data) => data.versions}
          columnConfig={[
            {
              type: 'title',
              headerValue: (data) => `${data.entity} ${titleize(data.type)} report`,
              headerAddition: (data) => <DataChip data={data} />,
              headerSubtitle: (data) => (
                <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
                  {`${titleize(data.type)} Report`}
                </Typography>
              ),
              value: (data) => `v${data.version}`,
              addition: (data) => <DataChip data={data} />,
              status: (data) => data.status,
              icon: (data) => REPORT_TYPE_ICONS[data.type],
              width: '1fr',
            },
            {
              type: 'date',
              headerValue: (data) => data.createdAt,
              value: (data) => data.createdAt,
              width: '110px',
            },
            {
              type: 'custom',
              headerValue: (data) => <AvatarIcon data={data} />,
              value: (data) => <AvatarIcon data={data} size="small" />,
              width: '140px',
            },
            {
              type: 'custom',
              value: (data) => {
                if (data.status === 'draft') {
                  return <EditIcon />;
                }
                return <div />;
              },
              width: '50px',
            },
          ]}
        />
      </Box>
    </PageShell>
  );
};
