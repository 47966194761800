import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../utils/constants';
import {
  ASSETS_BY_NAME_AND_COUNTRY_QUERY,
  BLOCKS_AND_LEASES_BY_NAME_AND_COUNTRY_QUERY,
  FACILITIES_BY_NAME_AND_COUNTRY_QUERY,
  FIELDS_BY_NAME_AND_COUNTRY_QUERY,
  LNG_PROJECTS_BY_NAME_AND_COUNTRY_QUERY,
  PIPELINE_NETWORKS_BY_NAME_AND_COUNTRY_QUERY,
  WELLS_BY_NAME_AND_COUNTRY_QUERY,
} from './operations';
import { MAP_SOURCE_IDS, MAP_SOURCE_RETRY_TIMEOUT } from '../../../components/Map/Utils/constants';
import React, { useContext, useEffect } from 'react';

import { AutoComplete } from '../../../components/AutoComplete';
import Button from '@mui/material/Button';
import { CountriesSelect } from '../../../components/Countries';
import Divider from '@mui/material/Divider';
import MapSearchContext from './searchContext';
import { SearchLabels } from './searchTypes';
import { getLatLongFromPoint } from '../../../utils/helpers';
import { getUserId } from '../../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  search: {
    ...theme.palette.mapTab,
    ...theme.palette.scrollbar,
  },
  accordion: {
    fontWeight: 'bold',
  },
  reset: {
    ...theme.palette.button,
    margin: '18px 8px',
  },
  root: {
    '&.MuiAutocomplete-root .MuiInputLabel-root': {
      paddingTop: '2px',
    },
    '&.MuiAutocomplete-root .MuiFilledInput-root > input': {
      padding: '24px 4px 10px',
    },
  },
  input: {
    '&.MuiFilledInput-root:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    '&.MuiFilledInput-root.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
  },
  label: {
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.fontSizes.smallest,
    },
  },
  listbox: {
    ...theme.palette.scrollbar,
  },
}));
// This variable is used to store the search object when the map isn't ready yet
let globalSearchObject = null;
export default ({ map, setCurrentItem, setIsInfoOpen, hidden }) => {
  const classes = useStyles();
  const { setFilter, resetState, state } = useContext(MapSearchContext);

  // Track if the first LNG projects load has happened so we can show the icons:
  const [isLngProjectsFirstLoad, setIsLngProjectsFirstLoad] = React.useState(true);
  const handleLNGProjectsLoaded = (data) => {
    const { lngProjectsByNameAndCountry: lngProjects } = data;
    if (!isLngProjectsFirstLoad) return;

    const lngFacilitiesSource = map?.getSource(MAP_SOURCE_IDS.lngFacilities);
    if (lngFacilitiesSource) {
      setIsLngProjectsFirstLoad(false);

      const features =
        lngProjects?.map((lngProject) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: getLatLongFromPoint(lngProject.geom),
          },
          properties: {
            id: lngProject.id,
            name: lngProject.name,
            facilityIcon: lngProject.iconName,
            countryIsoCode: lngProject.country.isoCode,
            geom: lngProject.geom,
            entityType: 'lng_project',
            category: lngProject.category,
          },
        })) || [];
      lngFacilitiesSource.setData({
        type: 'FeatureCollection',
        features,
      });
    } else {
      // If the source isn't ready yet, try again in 3 seconds:
      setTimeout(() => {
        handleLNGProjectsLoaded(data);
      }, MAP_SOURCE_RETRY_TIMEOUT);
    }
  };

  const resetSearch = () => {
    resetState();
    setCurrentItem(null);
    setIsInfoOpen(false);
    map?.resetZoom();
  };

  const handleSelect = (key, value, sourceLayerOverride) => {
    // Check if style is loaded
    if (map?.style?._loaded) {
      setFilter(key, value);
      if (value === null) return;
      const dimensions = { asset: 'NULL', country: 'NULL', company: 'NULL' };
      switch (key) {
        case 'assets':
        case 'blocks':
          dimensions['asset'] = value.name;
          dimensions['country'] = value.country.name;
          break;
        case 'country':
          dimensions['country'] = value.name;
          break;
      }
      gtag('event', ANALYTICS_EVENTS.event, {
        event_category: 'Maps',
        event_action: ANALYTICS_EVENT_ACTIONS.search,
        event_label: JSON.stringify({ [key]: value }),
        userId: getUserId(),
        ...dimensions,
      });

      if (key !== 'country') {
        const clickedFeature = map.createClickedFeatureForSearch(key, value);
        setCurrentItem({
          properties: clickedFeature,
          sourceLayer: sourceLayerOverride || key,
          layerCountry: value.country?.isoCode,
        });
        setIsInfoOpen(true);
      }

      map.flyTo({
        center: getLatLongFromPoint(value.geom),
        zoom:
          key === 'country'
            ? value.zoom
            : value.country?.isoCode !== 'USA' && (key === 'assets' || key === 'blocks')
            ? 9
            : 13,
      });
    } else {
      // If the map style isn't ready yet, save the search object to be used later
      globalSearchObject = {
        key,
        value,
        sourceLayerOverride,
      };
    }
  };

  // This use effect waits for the map to be loaded. When the map loads and there is a search object object present, it will:
  useEffect(() => {
    // Check if the search object and map are loaded
    if (globalSearchObject && map) {
      const { key, value, sourceLayerOverride } = globalSearchObject;
      // If the map has loaded, check if the style loaded again... If so, call handleSelect
      if (map?.style?._loaded) {
        handleSelect(key, value, sourceLayerOverride);
      } else {
        // If the style still hasn't loaded, add the handleSelect method to the style.load event listener
        // so we can handle panning/zooming once the map style is loaded
        map.on('style.load', () => handleSelect(key, value, sourceLayerOverride));
      }

      globalSearchObject = null;
    }
  }, [map, globalSearchObject]);

  return (
    !hidden && (
      <div className={classes.search}>
        <Button variant="contained" className={classes.reset} onClick={resetSearch}>
          reset search
        </Button>
        <Divider style={{ marginBottom: '15px' }} />
        <CountriesSelect
          styles={classes}
          multiple={false}
          onChange={(value) => handleSelect('country', value)}
          value={state.country}
          label="Go to Country/Area"
        />
        <div style={{ marginTop: '15px' }} />
        <AutoComplete
          styles={classes}
          getData={ASSETS_BY_NAME_AND_COUNTRY_QUERY}
          propVariables={{ country: state.country ? state.country.id : null }}
          onChange={(value) => handleSelect('assets', value)}
          value={state.assets}
          label={SearchLabels.asset}
          optionKey="menuName"
          menuItems={null}
          runQueryAfterOnChange={true}
          multiple={false}
          queryVariable="search"
          globalSearchType="ASSET"
          id="asset-search"
        />
        <AutoComplete
          styles={classes}
          getData={BLOCKS_AND_LEASES_BY_NAME_AND_COUNTRY_QUERY}
          propVariables={{ country: state.country ? state.country.id : null }}
          onChange={(value) =>
            handleSelect('blocks', value, value?.legacyLeaseId !== null && 'leases')
          }
          value={state.blocks}
          label={SearchLabels.block}
          optionKey="menuName"
          menuItems={null}
          runQueryAfterOnChange={true}
          multiple={false}
          queryVariable="search"
          globalSearchType="BLOCK"
        />
        <AutoComplete
          styles={classes}
          getData={FIELDS_BY_NAME_AND_COUNTRY_QUERY}
          propVariables={{ country: state.country ? state.country.id : null }}
          onChange={(value) => handleSelect('fields', value)}
          value={state.fields}
          label={SearchLabels.field}
          optionKey="menuName"
          menuItems={null}
          runQueryAfterOnChange={true}
          multiple={false}
          queryVariable="search"
          globalSearchType="FIELD"
        />
        <AutoComplete
          styles={classes}
          getData={WELLS_BY_NAME_AND_COUNTRY_QUERY}
          propVariables={{ country: state.country ? state.country.id : null }}
          onChange={(value) => handleSelect('wells', value)}
          value={state.wells}
          label={SearchLabels.well}
          optionKey="menuName"
          menuItems={null}
          runQueryAfterOnChange={true}
          multiple={false}
          queryVariable="search"
          globalSearchType="WELL"
        />
        <AutoComplete
          styles={classes}
          getData={FACILITIES_BY_NAME_AND_COUNTRY_QUERY}
          propVariables={{ country: state.country ? state.country.id : null }}
          onChange={(value) => handleSelect('facilities', value)}
          value={state.facilities}
          label={SearchLabels.facility}
          optionKey="menuName"
          menuItems={null}
          runQueryAfterOnChange={true}
          multiple={false}
          queryVariable="search"
          globalSearchType="FACILITY"
        />
        <AutoComplete
          styles={classes}
          getData={LNG_PROJECTS_BY_NAME_AND_COUNTRY_QUERY}
          onDataLoaded={handleLNGProjectsLoaded}
          propVariables={{ country: state.country?.id || null }}
          onChange={(value) => handleSelect('lngProjects', value, 'lng')}
          value={state.lngProjects}
          label={SearchLabels.lngFacility}
          optionKey="menuName"
          menuItems={null}
          runQueryAfterOnChange={true}
          multiple={false}
          queryVariable="search"
          globalSearchType="LNG_PROJECT"
        />
        <AutoComplete
          styles={classes}
          getData={PIPELINE_NETWORKS_BY_NAME_AND_COUNTRY_QUERY}
          propVariables={{ country: state.country ? state.country.id : null }}
          onChange={(value) => handleSelect('pipelineNetworks', value)}
          value={state.pipelineNetworks}
          label={SearchLabels.pipelineNetwork}
          optionKey="name"
          menuItems={null}
          runQueryAfterOnChange={true}
          multiple={false}
          queryVariable="search"
          globalSearchType="PIPELINE_NETWORK"
        />
      </div>
    )
  );
};
