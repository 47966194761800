import {
  TRANSACTIONS_AGGREGATE_BY_TITLE,
  TRANSACTIONS_AGGREGATE_COMPARE_TO_RESULT_KEYS_ENUM,
  TRANSACTIONS_AGGREGATE_COMPARE_TO_TITLES_ENUM,
  TRANSACTIONS_AGGREGATE_FIELDS_ENUM,
  TRANSACTIONS_STACK_BY_TYPE_ENUM,
} from '../../../types';

import { getScatterMarkerSize } from '../../../../../helpers';
import { titleize } from '../../../../../../../utils/helpers';
import { useGetMaTransactionsAggregates } from '../../../hooks/useGetMaTransactionsAggregates';
import { useGetMaTransactionsAggregatesStacked } from '../../../hooks/useGetMaTransactionsAggregatesStacked';
import { useMemo } from 'react';

const MAX_LEGEND_ITEMS = 5;

const getAltYAxisChartData = (data, aggregateBy, compareTo) => {
  const aggregateByTitle = titleize(aggregateBy);
  const altYAxisTitle = TRANSACTIONS_AGGREGATE_COMPARE_TO_TITLES_ENUM[compareTo];

  return [
    {
      x: data?.map((d) => d.group) || [],
      y: data?.map((d) => d[TRANSACTIONS_AGGREGATE_COMPARE_TO_RESULT_KEYS_ENUM[compareTo]]) || [],
      name: altYAxisTitle,
      yaxis: 'y2',
      type: 'scatter',
      mode: 'markers',
      marker: { size: getScatterMarkerSize(data) },
      hovertemplate: `${aggregateByTitle}: %{x}<br>${altYAxisTitle}: %{y:,.2f}<extra></extra>`,
    },
  ];
};

const getFlatChartData = (data, aggregateBy, compareTo) => {
  const aggregateByTitle = titleize(aggregateBy);
  const yAxisTitle = TRANSACTIONS_AGGREGATE_BY_TITLE;

  return [
    {
      x: data?.map((d) => d.group) || [],
      y: data?.map((d) => d.valuationsSum) || [],
      name: yAxisTitle,
      type: 'bar',
      hovertemplate: `${aggregateByTitle}: %{x}<br>${yAxisTitle}: %{y:,.2f}<extra></extra>`,
    },
    ...getAltYAxisChartData(data, aggregateBy, compareTo),
  ];
};

const getStackedChartData = (data, stackedData, stacks, aggregateBy, stackBy, compareTo) => {
  const yAxisTitle = TRANSACTIONS_AGGREGATE_BY_TITLE;
  const aggregateByTitle = titleize(aggregateBy);
  const stackByTitle = titleize(stackBy);

  const chartX = [];
  const chartYs = new Map(stacks.map((stack) => [stack, []]));
  // fill in the X and and primary Y values for each stack
  stackedData.forEach((d) => {
    // get list of X-axis values (e.g. countries, years, etc.)
    chartX.push(d.group);
    // get list of Y-axis values for each stack (i.e., buyer, seller)
    // if stack value is not present in the data, we need to default to 0 to keep the chart consistent
    stacks.forEach((stack) => chartYs.get(stack).push(d.values[stack]?.valuationsSum || 0));
  });

  return [...chartYs]
    .map(([stack, chartY], idx) => ({
      // same X-axis values for all stacks
      x: chartX,
      // one Y-axis value for stack
      y: chartY,
      name: stack,
      type: 'bar',
      hovertemplate: `${stackByTitle}: ${stack} <br>${aggregateByTitle}: %{x}<br>${yAxisTitle}: %{y:,.2f}<extra></extra>`,
      showlegend: idx < MAX_LEGEND_ITEMS,
    }))
    .concat(getAltYAxisChartData(data, aggregateBy, compareTo));
};

export const useGetChartData = ({ aggregateBy, stackBy, compareTo, adjustments, fields }) => {
  const { data, loading, error } = useGetMaTransactionsAggregates(aggregateBy, fields, adjustments);

  // only query for stacked data if we are stacking by a valid field
  const skipStackedQuery = !Object.values(TRANSACTIONS_STACK_BY_TYPE_ENUM).includes(stackBy);
  // we only allow stacking values for the primary Y-axis, which for now it's always valuations
  const stackByFields = [TRANSACTIONS_AGGREGATE_FIELDS_ENUM.VALUATIONS];
  const {
    data: stackedData,
    stacks,
    loading: stackedLoading,
    error: stackedError,
  } = useGetMaTransactionsAggregatesStacked(
    aggregateBy,
    stackBy,
    stackByFields,
    adjustments,
    skipStackedQuery,
  );

  const chartData = useMemo(() => {
    if (!data || !stackedData) return [];

    return skipStackedQuery
      ? getFlatChartData(data, aggregateBy, compareTo)
      : getStackedChartData(data, stackedData, stacks, aggregateBy, stackBy, compareTo);
  }, [stackBy, data, stackedData]);

  return { data: chartData, loading: loading || stackedLoading, error: error || stackedError };
};
