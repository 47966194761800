import React, { createContext, useState, useContext } from 'react';

const TabbedLegendContext = createContext();

export const TabbedLegendProvider = (props) => {
  const [tabChosen, setTabChosen] = useState(0);

  return (
    <TabbedLegendContext.Provider
      value={{
        tabChosen,
        setTabChosen,
      }}
    >
      {props.children}
    </TabbedLegendContext.Provider>
  );
};

export const useTabbedLegendContext = () => useContext(TabbedLegendContext);

export const TabbedLegendConsumer = TabbedLegendContext.Consumer;

export default TabbedLegendContext;
