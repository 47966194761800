import MapFilterContext, { MapFilterProvider } from './Filters/filterContext';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import Filters from './Filters';
import IconButton from '@mui/material/IconButton';
import Info from './Info';
import { LAYERS } from './constants';
import Map from '../../components/Map';
import { ScreenshotMode } from './ScreenshotMode';
import clsx from 'clsx';
import { getFulltextResultParameters } from '../../utils/globalSearch';
import { getLatLongFromPoint } from '../../utils/helpers';
import { getUserPreferenceMapStyle } from '../../components/Map/Utils/mapStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useFeatureFlags } from '../../contexts/FeatureFlagsContext';
import { DEFAULT_LEGEND_ITEMS } from '../../components/Map/Utils/constants';
import {
  TabbedLegendProvider,
  useTabbedLegendContext,
} from '../../components/Legend/tabbedLegend.context';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.sizes.fullPage,
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'hidden',
  },
  filterButton: {
    position: 'absolute',
    marginRight: theme.spacing(2),
    left: '25px',
    top: '80px',
    ...theme.palette.actionCustom,
  },
  filterButtonShift: {
    position: 'absolute',
    marginRight: theme.spacing(2),
    left: '315px',
    top: '80px',
    ...theme.palette.actionCustom,
    [theme.breakpoints.down('md')]: {
      left: '240px',
    },
  },
  infoButton: {
    position: 'absolute',
    marginRight: theme.spacing(2),
    right: '400px',
    top: '80px',
    zIndex: 2,
    transition: 'width 0.4s ease-in-out',
    ...theme.palette.actionCustom,
    [theme.breakpoints.down('lg')]: {
      right: '325px',
    },
  },
  infoButtonWider: {
    right: '905px',
    [theme.breakpoints.down('lg')]: {
      right: '725px',
    },
    [theme.breakpoints.down(1120)]: {
      right: '525px',
    },
    [theme.breakpoints.down(800)]: {
      right: '350px',
    },
  },
  screenshotModeButton: {
    position: 'absolute',
    marginRight: theme.spacing(2),
    right: '10px',
    top: '80px',
    zIndex: 4,
    ...theme.palette.actionCustom,
    [theme.breakpoints.down('lg')]: {
      right: '325px',
    },
  },
  screenshot: {
    maxWidth: '100%',
    height: 'auto',
    padding: '10px',
  },
  imageContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

let latLong;
let defaultMapPosition;

const OilAndGasMapPage = () => {
  const { setTabChosen } = useTabbedLegendContext();
  const classes = useStyles();
  const [showParticipationHistory, setShowParticipationHistory] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isScreenshotModeOpen, setIsScreenshotModeOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [map, setMap] = useState(null);
  const { inDevelopment, mapboxPoc } = useFeatureFlags();
  const [style, setStyle] = useState(
    inDevelopment ? 'monochrome' : getUserPreferenceMapStyle('satellite'),
  );
  // keep track of prevItem in order to remove highlight
  const prevItemRef = useRef();

  const { variables, secondaryLegendValues } = useContext(MapFilterContext);

  const showSecondaryLegend = variables.company.length > 0;

  const toggleFilterDrawer = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const toggleInfoDrawer = () => {
    setIsInfoOpen(!isInfoOpen);
  };

  const toggleScreenshotModeDrawer = useCallback(() => {
    setIsScreenshotModeOpen((v) => !v);
    setIsFilterOpen((v) => !v);
  }, []);

  // Handle search params
  // We only want to handle basins here
  const { entity, geom, name } = getFulltextResultParameters();
  if (entity === 'BASIN') {
    latLong = getLatLongFromPoint(geom);
    defaultMapPosition = { latLong: `[${latLong}]`, zoom: 4 };
  }
  // Keeping this in useEffect ensures this only gets called once
  useEffect(() => {
    if (entity === 'BASIN') {
      setCurrentItem({
        coordinates: { lat: latLong[1], lng: latLong[0] },
        layerCountry: null,
        properties: { name, id: null },
        sourceLayer: 'basins',
      });
      toggleInfoDrawer();
    }
  }, []);

  // Highlight layer
  useEffect(() => {
    const prevItem = prevItemRef.current;
    let layer = currentItem ? `${currentItem.layerCountry}_${currentItem.sourceLayer}` : null;
    layer = mapboxPoc && currentItem ? currentItem.sourceLayer : layer;
    if (prevItem !== null && prevItem !== undefined) {
      // remove previous layer highlight
      map.removeLayerHighlight(prevItem, layer);
    }
    if (currentItem !== null) {
      // highlight currentItem
      map.addLayerHighlight(currentItem, prevItemRef, layer);
    }
  }, [currentItem, mapboxPoc]);

  // Remove highlight when info panel is closed:
  useEffect(() => {
    const prevItem = prevItemRef.current;
    if (!isInfoOpen && !!prevItem) {
      map.removeLayerHighlight(prevItem, null);
    }
  }, [isInfoOpen]);

  const layerClickCallback = (newItem) => {
    toggleInfoDrawer();
    setCurrentItem(newItem);
  };

  const toggleMapStyle = (event) => {
    const newStyle = event.target.value;
    map.toggleMapStyle(newStyle);
  };
  const participantLegendShown = useRef(false);
  const tabbedItems = useMemo(() => {
    if (showSecondaryLegend && !!secondaryLegendValues) {
      const legendItemsNew = [
        ...DEFAULT_LEGEND_ITEMS,
        {
          label: 'Company',
          items: secondaryLegendValues,
        },
      ];
      if (participantLegendShown.current) {
        setTabChosen(legendItemsNew.length - 1);
      }
      participantLegendShown.current = true;
      return legendItemsNew;
    }
    participantLegendShown.current = false;
    return DEFAULT_LEGEND_ITEMS;
  }, [showSecondaryLegend, secondaryLegendValues, participantLegendShown.current]);

  return (
    <div className={classes.root}>
      <Map
        map={map}
        setMap={setMap}
        style={style}
        setStyle={setStyle}
        legend
        ruler
        control
        enableEntityHighlight
        layerClickCallback={layerClickCallback}
        layers={LAYERS}
        legendProps={{
          enabled: true,
          tabbed: true,
          tabbedItems: tabbedItems,
        }}
        defaultMapPosition={defaultMapPosition}
      />
      <Filters
        isOpen={isFilterOpen}
        style={style}
        toggleMapStyle={toggleMapStyle}
        map={map}
        setCurrentItem={setCurrentItem}
        setIsInfoOpen={setIsInfoOpen}
        shrink={showSecondaryLegend}
      />
      <Info
        isOpen={isInfoOpen}
        item={currentItem?.properties || null}
        layerName={currentItem?.sourceLayer || null}
        layerCountry={currentItem?.layerCountry || null}
        map={map}
        prevItemRef={prevItemRef}
        toggleScreenshotModeDrawer={toggleScreenshotModeDrawer}
        showParticipationHistory={showParticipationHistory}
        setShowParticipationHistory={setShowParticipationHistory}
        shrink={showSecondaryLegend}
      />
      <ScreenshotMode
        isOpen={isScreenshotModeOpen}
        item={currentItem?.properties || null}
        map={map}
      />
      <IconButton
        aria-label="open filter drawer"
        onClick={toggleFilterDrawer}
        edge="start"
        id="map-filter-button"
        className={isFilterOpen ? classes.filterButtonShift : classes.filterButton}
        size="large"
      >
        {isFilterOpen ? <CloseIcon /> : <FilterListIcon />}
      </IconButton>
      {isInfoOpen && (
        <IconButton
          aria-label="close info drawer"
          onClick={toggleInfoDrawer}
          edge="start"
          className={clsx(classes.infoButton, {
            [classes.infoButtonWider]: showParticipationHistory,
          })}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
      {isScreenshotModeOpen && (
        <IconButton
          id="close-screenshot-button"
          aria-label="close screenshot drawer"
          onClick={toggleScreenshotModeDrawer}
          edge="start"
          className={classes.screenshotModeButton}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
};
export default () => (
  <MapFilterProvider>
    <TabbedLegendProvider>
      <OilAndGasMapPage />
    </TabbedLegendProvider>
  </MapFilterProvider>
);
